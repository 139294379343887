/* cores */

$black: #333333;
$verde: #1A7057;
$cinza-claro: #EDEDED;
$cinza-escuro: #CCCCCC;
$texto: #999999;

$red: #D93636;
$blue: #1C71C6;
$yellow: #EDC12E;
$green: #46DB69;


@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');


body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 400;
}

#root{
  height: 100%;
}
.full-height {
  height: 100%;
}
// *{
//   height: 100%;
//   // overflow: scroll;
// }
html{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
}

body{
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  color: $texto;
  font-weight: 500;
  margin: 0;
  padding: 0;
  height: 100%;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent */
  
  }
}

@media(min-width: 1200px){
  .container{
    max-width: 1638px;
  }
}

header{
  background-color: $black;
  // height: 80px;
  padding: 10px 0; /* top/bottom - right/left */
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 999;

  .btn-logout{
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
  }
  
}




.content-container{
  // padding-top: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;

  // &::-webkit-scrollbar {
  //   width: 0;
  //   background: transparent; /* make scrollbar transparent */

  // }
}

.sidebar-left{
  background-color: $verde;
  color: #FFFFFF;
  // flex-basis: 25%;
  // width: 250px;
  flex-basis: 20%;
  overflow: hidden;
  margin-top: 50px;
  nav{
    position: fixed;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    
    // width: 100%;
    
    // justify-content: flex-start;
    // align-items: flex-start;
    margin: 30px auto;
    // width: 100%;
    a{
      color: #FFFFFF;
      width: 100%;
      border-bottom: 1px solid rgba(255,255,255, 0.15);
      margin: 15px 5px;
      padding: 15px 5px;
      height: auto;
      // padding: 5px 0 5px 30px;

      // position: relative;

    }
  }
}


main{
  padding: 50px 10px;
  flex-basis: 80%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // flex-basis: 100%;

  // height: 100%;
}

.sidebar-right{
  // height: calc(100vh - 60px);
  // height: 100%;
  border-left: 1px dashed $verde;
  margin: 30px 0;
  color: #FFFFFF;
  min-width: 200px;
  // height: 100%;
  overflow: scroll;
}

.status{
  .status-item{
    padding-left: 30px;
    position: relative;
    margin-bottom: 15px;
    span{
      font-size: 16px;
      line-height: 14px;
      text-transform: uppercase;
      display: block;
      color: $black;
    }
    small{
      font-size: 10px;
      color: $red;
      display: block;
    }
    
    &:before{
      content: "";
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../images/close.svg");
      background-size: cover;
      width: 18px;
      height: 18px;
      position: absolute;
      left: 0;
    }
    
    &.completed{
      &:before{
        background-image: url("../images/tick.svg");
      }
      small{
        color: $verde;
      }
    }
    
  }
}

.accordion{
  .card{
    margin-bottom: 20px;
    border: 0;
    border-radius: 0;
    
    .card-header{
      background-color: $verde;
      border: 0;
      height: 70px;
      h2{
        button{
          color: #FFFFFF;
          font-size: 20px;
          text-transform: uppercase;
          position: relative;
          text-decoration: none;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // border: 1px solid #000;
          &:focus{
            outline: 0;
            box-shadow: none;
          }

          
            // img.arrowAction{
              
            //   &.show{
            //     transform: rotate(180deg);
            //     border: 1px solid #000;
            //   }
              
            // }
          
          
          &:after{
            content: "";
            background-image: url("../images/arrow.svg");
            display: inline-block;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 0;
            background-repeat: no-repeat;
            background-position: center center;
            // transform: rotate(180deg);
          }
          
          &.collapsed{
            &:after{
              content: "";
              background-image: url("../images/arrow.svg");
              display: inline-block;
              width: 30px;
              height: 30px;
              position: absolute;
              right: 0;
              background-repeat: no-repeat;
              background-position: center center;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .card-body{
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.btn-container{
  margin-top: 50px;
  text-align: right;
  button{
    margin-left: 20px;
    width: 250px;
  }
  
}

.btn{
  border-radius: 0;
  border: 0;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  height: 70px;
  &.btn-primary{
    background-color: $verde;
    color: #FFFFFF;
    &:hover{
      background-color: $verde;
    }
  }
  &.btn-simular{
    background-color: $verde;
    color: #fff;
    width: 100%;
    height: 60px;
  }
  &.btn-simular-alt{
    background-color: #fff;
    color: $verde;
    border: 1px solid $verde;
    width: 100%;
    height: 60px;
  }
  &.btn-default{
    background-color: $black;
    color: #FFFFFF;
    &:hover{
      color: #FFFFFF;
      background-color: #000000;
    }
  }
}

form{
  .form-group{
    label{
      font-size: 16px;
      text-transform: uppercase;
    }
    input{
      background-color: $cinza-claro;
      border: 0;
      border-radius: 10px;
      color: $black;
      height: 60px;
    }
    textarea{
      background-color: $cinza-claro;
      border: 0;
      border-radius: 10px;
      color: $black;
      // height: 60px;
    }
    select{
      background-color: $cinza-claro;
      border: 0;
      border-radius: 10px;
      color: $black;
      height: 60px;
    }

    .form-error{
      border: 1px solid $red;
      // box-sizing: content-box;       
    }

    .form-error-text{
      color: $red;
      font-size: 12px;
    }
  }
}

h1{
  font-size: 28px;
  color: $verde;
  font-weight: 700;
}

.search{
  position: relative;
  input{
    background-color: $verde;
    color: #FFFFFF;
    border-radius: 8px;
    border: 0;
    height: 40px;
    padding: 10px;
    &::placeholder{
      color: #FFFFFF;
    }
  }
  button{
    border: 0;
    background: none;
    width: 30px;
    height: 30px;
    right: 3px;
    position: absolute;
    top: 3px;
    img{
      width: 100%;
      height: auto;
    }
  }
}

.legendas-bg{
  position: fixed;
  
}
.legendas{
  display: flex;
  align-items: center;
  
  background-color: #fff;
  z-index: 9999;
  width: 100%;

  

  .btn-atualizar{
    margin-left: auto;
    border: none;
    outline: none;
    background-color: $verde;
    color: #fff;
    padding: 5px 15px;

  }
  .legenda{
    margin-right: 20px;
    position: relative;
    padding-left: 20px;
    font-size: 12px;
    text-transform: uppercase;
    &:before{
      content: "";
      left: 0;
      position: absolute;
      width: 15px;
      height: 15px;
    }
    
    &.analise{
      &:before{
        background-color: $blue;
      }
    }
    &.reprovado{
      &:before{
        background-color: $red;
      }
    }
    &.pendente{
      &:before{
        background-color: $yellow;
      }
    }
    &.aprovado{
      &:before{
        background-color: $green;
      }
    }
  }
}

.card-list{
  overflow-y: scroll;
  height: calc(100vh - 85px - 70px - 30px);

  &::-webkit-scrollbar{
    width: 0;
    background: transparent; /* make scrollbar transparent */
  }
  .card{
    border: 0;
    // height: 120px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    flex-direction: row;
    margin-bottom: 20px;

    .card-content{
      display: flex;
      flex-direction: column;
      .flag{
        width: 250px;
        // min-width: 100%;
        height: 20px;
        text-align: center;
        color: #FFFFFF;
        border-radius: 5px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        padding: 15px 30px;
        // margin-left: auto;
        margin-top: 15px;
      }
  
      .nome{
        margin-top: 15px;
        width: 100%;
        color: $black;
      }
    }

    

    .btn-container{
      margin-left: auto;
      display: flex;
      flex-direction: column;
      margin: 0 0 0 auto;
      // border: 1px solid #000;
      button{
        height: 25px;
        font-size: 12px;
        width: 150px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        &.disabled{
          background-color: $cinza-escuro;
        }
      }
    }


    
    &.card-pendente{
      background-color: rgba(237, 193, 46, 0.15);
      .flag{
        background-color: rgba(237, 193, 46, 1);
      }
    }
    &.card-aprovado{
      background-color: rgba(70, 219, 105, 0.15);
      .flag{
        background-color: rgba(70, 219, 105, 1);
      }
    }
    &.card-reprovado{
      background-color: rgba(217, 54, 54, 0.15);
      .flag{
        background-color: rgba(217, 54, 54, 1);
      }
    }
    &.card-analise{
      background-color: rgba(28,113,198, 0.15);
      .flag{
        background-color: rgba(28,113,198, 1);
      }
    }
  }
}


.fullHeight{
  height: 100%;
  min-height: 600px;
  padding: 500px auto;
}
.loadingContainer{
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.validacao-wrapper{
  .validacao-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
    .validacao-success{
      color: $verde;
    }
    .validacao-error{
      color: $red;
    }
  }

  .validacao-text{
    text-align: center;
    font-size: 18px;
  }
}



.login-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  a {
    color: $verde;
  }

  .logo-box{
    background-color: $verde;
    background-clip: content-box;
  }

  img.login-logo{
    margin: 50px 0px;

  }

  input.green{
    background-color: $verde;
    color: #fff

  }
  input::placeholder{
    color: #fff;
    
  }

  button.login-btn{
    background-color: $black;
    color: #fff;
    outline: none;
    border: none;
    padding: 10px 50px;
    margin: 0 auto;
  }

  .check-success{
    background-color: $green;
    // border: 1px solid #000;
    border-radius: 50%;
    padding: 10px;
    color: #fff;
    margin: 30px auto;
  }
  h4{
    color: $verde;
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
  }
  h5{
    color: $verde;
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;
  }
}

button.btn-referencias{

  border: 1px solid;
  padding: 5px 15px;
  background-color: transparent;

  

  &.add{
    color: $verde;
    &:disabled{
      color: $texto;
    }
  }

  &.rem{
    margin-left: 15px;
    color: $red;
    &:disabled{
      color: $texto;
    }
  }


}

.loading{
  // padding-top: 150px;
  // border: 1px solid #000;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-loading{
  
}

.biometria-foto{

  img{
    width: 100%;
  }
  .biometria-foto-actions{
    // border: 1px solid #000;
    margin-top: 15px;
    h4{
      text-align: center;
    }
    .actions-container{
      // border: 1px solid #000;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      button{
        width: 150px;
        margin: 0 15px;
        font-weight: bold;

        &:disabled{
          color: $texto;
          border: 1px solid $texto;
        }
      }
    }
    
  }
}


.biometria-facial-formulario{
  display: flex;
  flex-direction: column;
  align-items: center;
  button{
    width: 250px;
  }
  // flex-direction: row;
  // justify-content: center;
  img.biometria-facial-display{
    width: 250px;
    margin: 15px auto;
    padding: 10px;
    border: 1px solid $texto;
    border-radius: 10px;
  }
}


.documentos-selecionados-upload-box{
  // border: 1px solid #000;
  border-top: 1px solid $texto;
  padding-top: 15px;

  label{
    // border: 1px solid #000;
    display: block;
    
    span.bg{
      display: flex;
      background-color: $cinza-claro;
      flex: 1;
      padding: 15px 30px;
      border-radius: 5px;
      justify-content: flex-start;


      span.icone{
        img{
          margin-right: 15px;
        }
      }

      span.texto{
        color: $texto;
        // border: 1px solid #000;
        width: 100%;
        text-align: center;
      }
    }

    input[type="file"]{
      display: none;
    }
  }
}

.documentos-lista{
  // border: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  .arquivo-preview{
    border: 1px solid #f1f1f1;
    // border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    
    
    img{
      // border: 1px solid #000;
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
    button{
      width: 100%;
      border-radius: 0;
      border: 0;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
      // height: 70px;
      outline: none;
      color: $red;
    }
  }
}

.btn-upload{
  label{

    input[type="file"]{
      display: none;
    }
  }
}

h3.section-title{
  border-bottom: 1px solid $verde;
  padding: 10px 0px;
  margin: 10px 0px;
  font-weight: bold;
}


.Toastify{
  // display: none;
}

.page-content{
  // width: 100%;
  display: block;
  margin-top: 30px;
  // padding-top: 50px;
  .proposta-lista{
    width: 100%;
    margin-top: 45px;

    .proposta-item-card{
      margin-bottom: 30px;

      hr{
        margin: 15px 0px;
      }
      .situacao-proposta{
        // border: 1px solid;
        border-radius: 30px;
        text-align: center;
        padding: 5px;
        // width: 50%;
        margin: 15px 0px;
      }

      .observacao-proposta{
        // border: 1px solid;
        h4{

        }
        p{

        }
      }

      .proposta-botoes{
        display: flex;
        flex-direction: column;
        a{
          width: 100%;
          margin-bottom: 10px;
          border: none;
          outline: none;
          background-color: $black;
          color: #fff;
          text-align: center;
        }
        button{
          width: 100%;
          margin-bottom: 10px;
          border: none;
          outline: none;
          background-color: $black;
          color: #fff;

          &:disabled{
            background-color: $cinza-escuro;
            color: $black;
          }
        }
      }


      &.EnviadoAnalise{
        .situacao-proposta{
          background-color: $blue;
          color: #fff;
        }
      }
      &.Temporaria{
        .situacao-proposta{
          background-color: $yellow;
          color: #fff;
        }
      }
      &.EnviadoAtendente{
        .situacao-proposta{
          background-color: $yellow;
          color: #fff;
        }
      }
      &.Aprovada{
        .situacao-proposta{
          background-color: $green;
          color: #fff;
        }
      }
      &.Reprovada{
        .situacao-proposta{
          background-color: $red;
          color: #fff;
        }
      }
      &.Cancelada{
        .situacao-proposta{
          background-color: $red;
          color: #fff;
        }
      }
      &.AprovadaEncerrada{
        .situacao-proposta{
          background-color: $green;
          color: #fff;
        }
      }
      &.ReprovadaEncerrada{
        .situacao-proposta{
          background-color: $red;
          color: #fff;
        }
      }
    }
  }
}


.hiddenField{
  display: none;
}